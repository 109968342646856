import { SvgIcon } from "@mui/material";

function SignIcon(props) {
  return (
    <SvgIcon {...props}>
      <path d="M8.67969 8.99993h1v-4h-1c-1.10469 0-2 .89531-2 2 0 1.10468.89531 2 2 2Zm-6 7.99997c0 1.1047.89531 2 2 2h1v-4h-1c-1.10469 0-2 .8953-2 2Zm10.54561-4c-1.0909 0-2.38.41-3.2728 1-.77469.5119-1.39125 1-3.27281 1v4l1.79781.4991c.81906.2275 1.6566.41 2.5097.4703 1.0215.0722 2.05.0209 3.0494-.1937 3.4212-.735 4.6431-2.3625 4.6431-3.7757 0-2-2.6306-3-5.4544-3Zm4.8113-8.77529c-.9994-.21468-2.0279-.26625-3.0494-.19375-.8531.06032-1.6906.24282-2.5097.47l-1.7978.49907v4c1.8812 0 2.4981.48812 3.2728 1 .8928.58997 2.1819.99997 3.2728.99997 2.8238 0 5.4544-.99997 5.4544-2.99997 0-1.41313-1.2219-3.04063-4.6431-3.77532Z" />
    </SvgIcon>
  );
}

export default SignIcon;
